/*
 * @Descripttion:
 * @version:
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2020-12-26 09:30:20
 */
import Vue from "vue";
import VueRouter from "vue-router";
import global from './../common/global'
import { Icon,Dialog} from 'vant'

Vue.use(VueRouter);
Vue.use(Dialog);

const routes = [
    {
        path: "/index",
        name: "index",
        component: () => import('../views/Index.vue')
    },
    {
        path : '/login',
        name : 'login',
        component:() => import('../views/Login.vue')
    },
    {
        path : '/home',
        name : 'home',
        component : ()=> import('../views/Home.vue')
    },
    {
        path : '/expertInfo',
        name : 'expertInfo',
        component: () => import('../views/ExpertInfo.vue')
    },
    {
        path: "/mine",
        name: "mine",
        component: () =>
        import("../views/Mine.vue")
    },
    {
        path : '/company',
        name : 'company',
        component: () => import('../views/mine/Company.vue')
    },
    {
        path : '/file/:type',
        name : 'file',
        meta:{
            keepAlive : true
        },
        component: () => import('../views/mine/FileUpload.vue')
    },
    {
        path : '/expert',
        name : 'expert',
        component:() => import('../views/mine/Expert.vue'),
        des : '专家管理'
    },
    {
        path : '/expertAdd',
        name : 'expertAdd',
        component:() => import('../views/ExpertAdd.vue'),
        des : '专家添加'
    },
    {
        path : '/expertEdit',
        name : 'expertEdit',
        component:() => import('../views/ExpertEdit.vue'),
        des : '专家修改'
    },
    {
        path : '/review/:name',
        name : 'review',
        component: () => import('../views/Review.vue'),
        des : '评审专业'
    },
    {
        path : '/record',
        name : 'record',
        component:() => import('../views/mine/Record.vue')
    },
    {
        path : '/projectInfo',
        name : 'projectInfo',
        component:() => import('../views/ProjectInfo.vue'),
        des : '项目详情'
    },
    {
        path : '/confirmExpert/:name',
        name : 'confirmExpert',
        component:() => import('../views/ConfirmExpert.vue'),
        des : '专家确认'
    },
    {
        path : '/advise',
        name : 'advise',
        component:() => import('../views/mine/Advise.vue')
    },
    {
        path: '/publicity',
        name: 'publicity',
        component: () =>import('../views/mine/Publicity.vue'),
        children: [
            {
                path: '/publicity/scan',
                name: '/publicity-scan',
                component: () =>import('../views/publicity/Scan.vue')
            },
            {
                path: '/publicity/statistics',
                name: '/publicity-statistics',
                component: () =>import('../views/publicity/Statistics.vue')
            }
        ],
        redirect: '/publicity/scan'
    },
    {
        path : '/account',
        name : 'account',
        // meta : {
        //     keepAlive : true
        // },
        component:() => import('../views/mine/Account.vue'),
        // children : [
        //     {
        //         path : '/account/scan',
        //         name : 'accountScan',
        //         component: () => import('../views/user/Scan.vue')
        //     },
        //     {
        //         path : '/account/user',
        //         name : 'accountUser',
        //         component: () => import('../views/user/User.vue')
        //     }
        // ],
        // redirect : '/account/scan'
    },
    {
        path : '/profession',
        name : 'profession',
        component:() => import('../views/mine/Profession.vue'),
        children : [
            {
                path : '/profession/info',
                name : 'professionInfo',
                component:() => import('../views/profession/Info.vue')
            },
            {
                path : '/profession/edit',
                name : 'professionEdit',
                component:() => import('../views/profession/Edit.vue')
            },
        ],
        redirect : '/profession/info'

    },
    {
        path : '/extraction',
        name : 'extraction',
        component:() => import('../views/Extraction.vue'),
        children :[
            {
                path : '/step1',
                name : 'step1',
                meta : {
                    keepAlive : true
                },
                component:() => import('../views/extraction/Step1.vue')
            },
            {
                path : '/step2',
                name : 'step2',
                component:() => import('../views/extraction/Step2.vue')
            },
            {
                path : '/step3',
                name : 'step3',
                component:() => import('../views/extraction/Step3.vue')
            },
            {
                path : '/step4',
                name : 'step4',
                component:() => import('../views/extraction/Step4.vue')
            }

        ],
        redirect : '/step1'
    },
    {
        path : '/renew',
        name : 'renew',
        component:() => import('../views/Renew.vue')
    },
    {
        path : '/usercheck',
        name : 'usercheck',
        component:() => import('../views/login/usercheck.vue')
    },
    {
        path : '/selectmajors',
        name : 'selectmajors',
        component:() => import('../views/extraction/SelectMajors.vue')
    },
    {
        path : '/nickname',
        name : 'nickname',
        component:() => import('../views/login/nickname.vue')
    },
    /** 宣传页 */
    {
        path: '/',
        name: 'intro',
        component: () =>import('../views/intro/Index.vue')
    },
    {
        path: '/chouqu',
        name: 'chouqu',
        component: () => import('../views/intro/Chouqu.vue')
    },
    {
        path: '/zhuanjia',
        name: 'zhuanjia',
        component: ()=>import('./../views/intro/Zhuanjia.vue')
    },
    {
        path: '/zhuanyeku',
        name: 'zhuanyeku',
        component: ()=>import('./../views/intro/Zhuanyeku.vue')
    },
    {
        path: '/buchou',
        name: 'buchou',
        component: ()=>import('./../views/intro/Buchou.vue')
    },
    {
        path: '/mobile',
        name: 'mobile',
        component: ()=>import('./../views/intro/Mobile.vue')
    },
    {
        path: '/pc',
        name: 'pc',
        component: ()=>import('./../views/intro/PC.vue')
    },
	//测试用
	{
	    path : '/test',
	    name : 'test',
	    component:() => import('../views/test.vue')
    },
    {
        path: '/payReceive',
        name: 'payReceive',
        component: () => import('../views/PayReceive.vue'),
        des: '接收微信支付通知'
    },
    {
        path: '/expertRecord',
        name: 'expertRecord',
        component: () => import('../views/ExpertRecord.vue'),
        des: '项目抽取记录'
    },
    {
        path : '/smsrecharge',
        name: 'smsrecharge',
        component: () => import('../views/smsrecharge.vue'),
        des: '短信充值'
    },
    {
      path: '/thanks',
      name: 'thanks',
      component: () => import('../views/Thanks.vue'),
      des: '鸣谢'
    },
    {
      path: '/org',
      name: 'org',
      component: () => import('../views/Org.vue'),
      des: '入驻机构'
    },
    {
      path: '/orgInfo',
      name: 'orgInfo',
      component: () => import('../views/OrgInfo.vue'),
      des: '机构详情'
    },
    /** 项目演示 **/
    {
        path: '/demo',
        name: 'demo',
        component: () => import ('../views/demo/Demo.vue'),
        children: [

            {
                path:'/demo-1',
                name: 'demo-1',
                meta: {
                    keepAlive: true
                },
                component: () => import('../views/demo/Demo-Step1.vue')
            },
            {
                path:'/demo-2',
                name: 'demo-2',
                component: () => import('../views/demo/Demo-Step2.vue')
            },
            {
                path:'/demo-3',
                name: 'demo-3',
                component: () => import('../views/demo/Demo-Step3.vue')
            },
            {
                path:'/demo-4',
                name: 'demo-4',
                component: () => import('../views/demo/Demo-Step4.vue')
            }
        ],
        redirect : '/demo-1'
    },
    {
        path : '/demoMajor',
        name: 'demoMajor',
        component: () => import('../views/demo/Demo-Major.vue')
    },
	//企业微信打开第三方应用：网页授权登录URL
    {
      path: '/webauthorize',
      name: 'webauthorize',
      component: () => import('../views/WebAuthorize.vue'),
    },
	//机构完善信息
	{
	  path: '/orgfinish',
	  name: 'orgfinish',
	  component: () => import('../views/orgfinish.vue'),
	},
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: () => ({ y: 0 }),
});

router.beforeEach((to, from, next) =>{
  /**
   * 1.不需校验微信环境的路由
   * 2.需要校验微信环境
   *  2.1 不需要校验缓存中的机构信息
   *  2.2 需要校验机构信息
   *    2.2.1 无机构信息，只能跳转 login 和 index
   *    2.2.2 有机构信息： 禁止跳转 login 和 index
   *      2.2.2.1 未开通服务： 禁止跳转 includeRoute 中的路由
   */
    if(global.freeRoute.join(",").indexOf(to.name) >= 0)
        next()
    else if(!(/micromessenger/.test(navigator.userAgent.toLowerCase())))
        alert("请在微信环境中打开")
    else if(global.excludeRoute.join(",").indexOf(to.name) >=0)
        next()
    else{
        let orgExternal = JSON.parse(localStorage.getItem("vuejsedCurrentOrgExternal"))
        if(!orgExternal || !orgExternal.value|| JSON.stringify(orgExternal.value) =="{}"){
            if(to.name =='login' || to.name =='index')
                next(true)
            else
                next('/index')
        }else if(to.name == 'login' || to.name =='index')
            next(false)
        else if(global.includeRoute.join(",").indexOf(to.name) >=0){
            /** 未开通服务 或 服务已到期 禁止跳转 */
            if(orgExternal.value.serviceStatus==null||orgExternal.value.serviceStatus=='00'||orgExternal.value.serviceStatus=='02'){
                /** 若用户 为直接输入网址方式进入，则跳转至 mine */
                if(from.name == null)
                    next('/mine')
                else{
                  Dialog.confirm({
                    title : '提示',
                    message : '本功能需要开通专家记服务才能使用！',
                    confirmButtonText : '去开通服务',
                    cancelButtonText: '取消',
                    }).then(()=>{ //确认
                        next('/renew')
                    }).catch(() =>{
                        console.log('取消')
                    })
                    return;
                }
            }else
                next()
        }else
            next()
    }
})

export default router;
