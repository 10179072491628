/*
 * @Descripttion:
 * @version:
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2020-12-26 09:30:20
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from 'axios';
import vueAxios from 'vue-axios';
import 'lib-flexible/flexible';
import Storage from 'vue-ls'
let options = {
    namespace : 'vuejs',
    name : 'ls',
    storage : 'local'
}
Vue.use(Storage,options)
Vue.config.productionTip = false;

// vant 常用组件--全局引入
import { NavBar, Button, Toast } from 'vant';
Vue.use(NavBar);
Vue.use(Button);
Vue.use(Toast);
Vue.use(vueAxios, axios)

Vue.prototype.$ajax = axios;

// 引入样式
import './../public/static/init.css'
import './../public/static/css/export.css'
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
