/*
 * @Descripttion:
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2020-10-13 08:27:35
 */

import area from "@/common/area"
export default {
	// 企业微信
	suiteId: 'ww63546fb25ff74d56',
    // 小程序 -- 生产环境
    miniAppid: 'wx442fbc8fa372bffa',
    originalId: 'gh_5bc94b373ad5',
    // 小程序 -- 跳转小程序URL链接
    miniUrl : 'pages/login/login.html?customerId=',
    // 公众号 - 正式-专家记公众号
    appid: 'wx0b4aa0259537bec2',
    secret: 'c492001d59ba5e3980ebbabbb86470d2',
    //ws url 正式站
    wsurl: 'wss://hiweke.bidsoon.cn/integral-websocket/',
    //店铺分享url---正式
    shareUrl:'http://hiweke.bidsoon.cn/QrcodeUrl',
    shareTitle:'余额记店铺分享',
    shareDesc:'余额记店铺分享',
    shareImgUrl:'',
    //顾客分享url---正式
    customerShareUrl:'http://hiweke.bidsoon.cn/attention',
    customerShareTitle:'请关注嗨微客公众号',
    customerShareDesc:'请关注嗨微客公众号',
    customerShareImgUrl:'/static/img/mine/gold.png',
    //openid temp store 回退使用
    //正式环境
    temp_store_openid_key:"rollback-temp-openid-pro",

    // 关注公众号链接
    // 嗨微客
    OfficialAccountUrl : 'http://mp.weixin.qq.com/mp/getmasssendmsg?__biz=Mzg4NTM4NzQxMg==#wechat_webview_type=1&wechat_redirect',
    //生成环境
    domianURL: 'https://hiweke.bidsoon.cn/sys/common/static/',
    // Pc端项目地址
    pcUrl: 'http://jjcsb.bidsoon.cn/edpc/',
    /** 可自由进入，不需校验微信环境 */
    freeRoute: ["intro","chouqu","zhuanjia","zhuanyeku","buchou","mobile","pc","test","webauthorize"],
    /** 需要校验微信环境，但是不需要校验缓存中的机构信息 */
    excludeRoute:["usercheck"],
    /** 需要校验微信环境，需要校验机构信息，需要校验是否开通服务 */
    includeRoute: ["expert","extract","step1","step2","step3","professionInfo","expertAdd"],

    areaList: area,
    randomStr: function (num) {
        num = num || 16;
        let result = '';
        let str = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
        for (let index = 0; index < num; index++) {
            result += str.charAt(Math.floor(Math.random() * str.length))

        }
        return result
    },
    /**
     * 将 Date 转化为指定格式的String
     * @param {*} date
     * @param {*} fmt
     */
    formatDateStr(date, fmt) {
        let o = {
            "M+": date.getMonth() + 1, //月份
            "d+": date.getDate(), //日
            "h+": date.getHours(), //小时
            "m+": date.getMinutes(), //分
            "s+": date.getSeconds(), //秒
            "q+": Math.floor((date.getMonth() + 3) / 3), //季度
            "S": date.getMilliseconds() //毫秒
        };
        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (let k in o)
            if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;
    },
    //初始化后台省市县数据 显示在页面上
    //province省 city市 area区 例如数据： 省-市-区 (370000,370200,370214)
    getAreaString(province, city, area) {
        let str = "";
        let tt="";
        if (province != null && province != '') {
            //遍历省
            for (let [key, value] of Object.entries(this.areaList.province_list)) {
                //console.log(key + ':' + value);
                if (key == province) {
                    str += value;
                    break;
                }
            }
        }
        if (city != null && city != '') {
            //遍历市
            for (let [key, value] of Object.entries(this.areaList.city_list)) {
                //console.log(key + ':' + value);
                if (key == city) {
                    if(str != value)
                    str += value;
                    tt = value
                    break;
                }
            }
        }
        if (area != null && area != '') {
            //遍历区
            for (let [key, value] of Object.entries(this.areaList.county_list)) {
                // console.log(key + ':' + value);
                if (key == area) {
                    if(tt != value)
                    str += value;
                    break;
                }
            }
        }
        return str;
    },

}
